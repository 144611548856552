import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col, useScreenClass, Hidden } from "react-grid-system"
import styled from "styled-components"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Main from "../components/main"

const ThankYouPage = (props) => {
  const { seoTitle, seoDescription, seoHelmet } = props.data.contentfulPage
  const screenClass = useScreenClass()
  return (
    <>
      <SEO
        title={seoTitle ? seoTitle : ``}
        description={
          seoDescription && seoDescription.seoDescription
            ? seoDescription.seoDescription
            : ``
        }
        pageUrl={props?.location?.href}
      />
      <Layout>
        <Main>
          <section>
            <Container
              style={{
                paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                  ? "3.4rem"
                  : "33px",
                paddingRight: ["md", "lg", "xl"].includes(screenClass)
                  ? "3.4rem"
                  : "33px",
              }}
            >
              <Row>
                <Col lg={8} offset={{ lg: 2 }}>
                  <h1 className={`headline`}>
                    {props.data.contentfulPage.mainHeadline}
                  </h1>
                  {documentToReactComponents(
                    JSON.parse(props.data.contentfulPage.text.raw)
                  )}
                </Col>
              </Row>
            </Container>
          </section>
        </Main>
      </Layout>
    </>
  )
}

export const query = graphql`
  query ThankYouDownloadQuery {
    contentfulPage(slug: { eq: "thank-you-download" }) {
      id
      slug
      name
      mainHeadline
      text {
        raw
      }
      seoTitle
      seoDescription {
        seoDescription
      }
      seoHelmet {
        seoHelmet
      }
    }
  }
`

export default ThankYouPage
